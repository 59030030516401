
import { Options, Vue } from 'vue-class-component'
import checkout from '@/components/Frontend/Checkout/index.vue'

@Options({
  components: {
    checkout
  }
})
export default class Checkout extends Vue {}
