import http from '../http-common'
import { APIBaseFunctions, APIDataSliceObj, defaultAPILimit } from '@/components/Utility/APIBase'

class ClubPurchaseDataService extends APIBaseFunctions {
  private cachedClubPurchaseDataSliceObj: APIDataSliceObj = {
    slicemode: 0,
    start: 0,
    limit: defaultAPILimit,
    page: 0,
    pagesize: 0,
    totalcount: 0
  }

  getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
    const currentDataSliceObj = ClubPurchaseDataService.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedClubPurchaseDataSliceObj }, dataSliceObj)

    if (sortingOrder.length > 0) {
      return http.get(`/klubkoebs?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    return http.get(`/klubkoebs?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
  }

  get (id: string) {
    return http.get(`/klubkoebs/${id}`)
  }

  async asyncGet (id: string) {
    return http.get(`/klubkoebs/${id}`)
  }

  getCount (extraCountParameter = '') {
    return http.get('/klubkoebs/count' + (extraCountParameter.length > 0 ? `?${extraCountParameter}` : ''))
  }

  create (data: any) {
    return http.post('/klubkoebs', data)
  }

  async asyncCreate (data: any) {
    return http.post('/klubkoebs', data)
  }

  update (id: string, data: any) {
    return http.put(`/klubkoebs/${id}`, data)
  }

  delete (id: string) {
    return http.delete(`/klubkoebs/${id}`)
  }

  deleteAll () {
    return http.delete('/klubkoebs')
  }
}

export default new ClubPurchaseDataService()
