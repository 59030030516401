import http from '../http-common'
import { APIBaseFunctions, APIDataSliceObj, defaultAPILimit } from '@/components/Utility/APIBase'

export class Events extends APIBaseFunctions {
  static EventsDataService = class {
    private static cachedEventsDataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      const currentDataSliceObj = Events.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedEventsDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`/events?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/events?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static get (id: string) {
      return http.get(`/events/${id}`)
    }

    static getCount (extraCountParameter = '') {
      return http.get('/events/count' + (extraCountParameter.length > 0 ? `?${extraCountParameter}` : ''))
    }

    static create (data: any) {
      return http.post('/events', data)
    }

    static update (id: string, data: any) {
      return http.put(`/events/${id}`, data)
    }

    static delete (id: string) {
      return http.delete(`/events/${id}`)
    }

    static deleteAll () {
      return http.delete('/events')
    }

    static findByEventStatus (status: boolean, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      let sortingOrderParameter = ''
      const currentDataSliceObj = Events.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedEventsDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        sortingOrderParameter = `&_sort=${sortingOrder}`
      }

      return http.get(`/events?event_status=${status}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static findBySearchTerm (status: boolean, ignoreStatus: boolean, searchTerm: string, sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      let retVal
      let sortingOrderParameter = ''
      let priceSearchParameter = ''
      const currentDataSliceObj = Events.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedEventsDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        sortingOrderParameter = `&_sort=${sortingOrder}`
      }

      if (!isNaN(Number(searchTerm))) {
        priceSearchParameter = `&_where[_or][3][event_pris_contains]=${searchTerm}`
      }

      if (ignoreStatus) {
        retVal = http.get(`/events?_where[_or][0][event_titel_contains]=${searchTerm}&_where[_or][1][event_beskrivelse_contains]=${searchTerm}&_where[_or][2][event_sted_contains]=${searchTerm}${priceSearchParameter}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      } else {
        retVal = http.get(`/events?event_status=${status}&_where[_or][0][event_titel_contains]=${searchTerm}&_where[_or][1][event_beskrivelse_contains]=${searchTerm}&_where[_or][2][event_sted_contains]=${searchTerm}${priceSearchParameter}${sortingOrderParameter}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return retVal
    }
  }

  static EventTypesDataService = class {
    private static cachedEventTypesDataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null = null, extraParameter = '') {
      const currentDataSliceObj = Events.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedEventTypesDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`/event-types?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/event-types?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static get (id: string) {
      return http.get(`/event-types/${id}`)
    }

    static getCount () {
      return http.get('/event-types/count')
    }

    static create (data: any) {
      return http.post('/event-types', data)
    }

    static update (id: string, data: any) {
      return http.put(`/event-types/${id}`, data)
    }

    static delete (id: string) {
      return http.delete(`/event-types/${id}`)
    }

    static deleteAll () {
      return http.delete('/event-types')
    }
  }

  static EventRegistrationDataService = class {
    private static cachedEventRegistrationDataSliceObj: APIDataSliceObj = {
      slicemode: 0,
      start: 0,
      limit: defaultAPILimit,
      page: 0,
      pagesize: 0,
      totalcount: 0
    }

    static getAll (sortingOrder = '', dataSliceObj: APIDataSliceObj | null, extraParameter = '') {
      const currentDataSliceObj = Events.processDataSliceObj({ cachedServicesDataSliceObj: this.cachedEventRegistrationDataSliceObj }, dataSliceObj)

      if (sortingOrder.length > 0) {
        return http.get(`/event-tilmeldings?_sort=${sortingOrder}&_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
      }

      return http.get(`/event-tilmeldings?_start=${currentDataSliceObj.start}&_limit=${currentDataSliceObj.limit}` + (extraParameter.length > 0 ? `&${extraParameter}` : ''))
    }

    static get (id: string) {
      return http.get(`/event-tilmeldings/${id}`)
    }

    static getCount (extraCountParameter = '') {
      return http.get('/event-tilmeldings' + (extraCountParameter.length > 0 ? `?${extraCountParameter}` : ''))
    }

    static create (data: any) {
      return http.post('/event-tilmeldings', data)
    }

    static update (id: string, data: any) {
      return http.put(`/event-tilmeldings/${id}`, data)
    }

    static delete (id: string) {
      return http.delete(`/event-tilmeldings/${id}`)
    }

    static deleteAll () {
      return http.delete('/event-tilmeldings')
    }
  }
}

export default new Events()
